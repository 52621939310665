import React from 'react';
import { Link } from 'gatsby';
import logomark from '../images/logomark.svg';

import Container from 'components/Container';

const Header = () => {
  return (
    <header className="mt-6">
      <Container>
        <div className="flex justify-between content-end items-center border-b-2">
          <div className="mb-4 md:mb-0">
            <img src={logomark} alt="Justin Armburger" className=""/>
            <Link to="/" className="font-serif text-4xl md:text-5xl my-2 md:my-4 inline-block hover:text-pink transition-colors duration-200">Justin Armburger</Link>
            <h2 className="md:ml-4 w-44 inline-block leading-4 text-sm">Designer. Professor. Gardener. Big Mac Lover.</h2>
          </div>
          <ul className="main-nav flex flex-col w-32">
            <li className="border-t py-2">
              <Link to="/" className="font-mono hover:text-pink duration-200" activeClassName="active" ><span>01 </span>Work</Link>
            </li>
            <li className="border-t py-2">
              <Link to="/about" className="font-mono hover:text-pink duration-200" activeClassName="active"><span className="">02 </span>About</Link>
            </li>
          </ul>
        </div>
      </Container>
    </header>
  );
};

export default Header;