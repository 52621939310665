import React from 'react';

import Container from 'components/Container';

const Footer = () => {
  return (
    <footer>
      <Container>
          <div className="flex justify-center p-4 font-mono text-sm">
              <span>&copy; {new Date().getFullYear()} Justin Armburger</span>
          </div>
      </Container>
    </footer>
  );
};

export default Footer;